import React from 'react';
import { MarketItemViewModel } from '~/lib/data-contract';
import Check from '$icons/check.svg';
import { StyledMarketImage, StyledMarketLink, StyledMarketText } from '../styled';
import { Image } from '~/shared/components';

type Props = MarketItemViewModel & {
    active?: boolean;
};

export const Market = (props: Props) => {
    const { countryName, languageName, currency, active, locale, countryKey } = props;

    return (
        <StyledMarketLink href={`/${locale}`} active={active} type="raw">
            <StyledMarketImage>
                <Image
                    src={`/assets/flags/${countryKey?.toLowerCase()}.png`}
                    height="20"
                    width="30"
                    alt={countryName ?? ''}
                    style={{ objectFit: 'contain', marginTop: 10 }}
                    skeletonShade="none"
                    unoptimized
                />
            </StyledMarketImage>
            <span>
                <StyledMarketText variant="body">
                    {countryName} {currency ? ` - ${currency}` : undefined}
                </StyledMarketText>
                {languageName}
            </span>
            {active && <Check />}
        </StyledMarketLink>
    );
};
